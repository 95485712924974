import React, { useContext } from "react";
import EditIcon from "@mui/icons-material/Edit";
import nodeContext from "../../../context/nodeContext";

const PercentageBtn = ({ id, ...rest }) => {
  const { setNodes } = useContext(nodeContext);

  const handleClick = () => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          if (node.data.percentage && 100 > node.data.percentage > 0) {
            return {
              ...node,
              data: {
                ...node.data,
                percentage: node.data.percentage + 1,
              },
            };
          } else if (!node.data.percentage) {
            return {
              ...node,
              data: {
                ...node.data,
                percentage: 1,
              },
            };
          }
        }
        return node;
      })
    );
  };

  return <EditIcon onClick={handleClick} {...rest} />;
};

export default PercentageBtn;
