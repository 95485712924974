import {
  Box,
  ClickAwayListener,
  Grid,
  Grow,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import LineImg from "assets/images/LineImg";
const MapSelector = () => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <Typography
        ref={anchorRef}
        onClick={handleToggle}
        sx={{ cursor: "pointer", display: "flex", flexDirection: "column-reverse", ml: 1, mr: 4 }}
      >
        <LineImg />
        My principal map
      </Typography>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom"
        transition
        disablePortal
        modifiers={[
          {
            name: "flip",
            enabled: false,
          },
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "right top",
            }}
          >
            <Paper elevation={3} sx={{ mt: 2, p: 2, pb: 3, borderRadius: "12px" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  <Typography sx={{ mb: 3 }} variant="body1">
                    Sélectionnez votre carte
                  </Typography>
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems="center"
                    spacing={8}
                    sx={{ mb: 2 }}
                  >
                    <Circle label="Lacus Industries" color="#8851A0" />
                    <Circle label=" carte2" color="#B7D3DF" />
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems="center"
                    spacing={8}
                  >
                    <Circle label=" carte3" color="#f0c453" />
                    <Circle label=" carte4" color="#d89292" />
                  </Stack>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const Circle = ({ color, label }) => {
  return (
    <Stack
      justifyContent={"center"}
      alignItems="center"
      sx={{
        color: "#fff",
        cursor: "pointer",
        width: "70px",
        height: "70px",
        borderRadius: "50px",
        background: color,
      }}
    >
      <Typography variant="caption" align="center">
        {label}
      </Typography>
    </Stack>
  );
};

export default MapSelector;
