import * as React from "react";
import "./style.css";

const Check = (props) => {
  return (
    <svg
      id="successAnimation"
      className="animated"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 70 70"
      {...props}
    >
      <circle
        id="successAnimationResult"
        stroke="url(#a)"
        cx="35"
        cy="35"
        r="24"
        strokeWidth="2"
        strokeLinecap="round"
        fill="transparent"
      />
      <circle
        id="successAnimationCircle"
        cx="35"
        cy="35"
        r="24"
        stroke="url(#a)"
        strokeWidth="2"
        strokeLinecap="round"
        fill="transparent"
      />
      <polyline
        id="successAnimationCheck"
        stroke="url(#b)"
        strokeWidth="2"
        points="23 34 34 43 47 27"
        fill="transparent"
      />
      <polyline
        id="successAnimationResult"
        stroke="url(#b)"
        strokeWidth="2"
        points="23 34 34 43 47 27"
        fill="transparent"
      />
      <defs>
        <linearGradient id="a" x1={38.5} y1={11} x2={35} y2={59} gradientUnits="userSpaceOnUse">
          <stop stopColor="#F05673" />
          <stop offset={0.516} stopColor="#7B314E" />
          <stop offset={1} stopColor="#14102D" />
        </linearGradient>
        <linearGradient id="b" x1={35} y1={27} x2={38} y2={57} gradientUnits="userSpaceOnUse">
          <stop stopColor="#F05673" />
          <stop offset={1} stopColor="#14102D" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Check;
