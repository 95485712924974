import React from "react";
import CreateNodes from "../CreateNodes";

const CreateNodesStep = () => {
  const modules = ["Maturité digitale", "Règlementation", "Marché", "Technologie"];
  return (
    <>
      <CreateNodes modules={modules} />
    </>
  );
};

export default CreateNodesStep;
