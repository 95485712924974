import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import LangSelector from "./LangSelector";

const Menu = () => {
  const navigate = useNavigate();
  const handleClick=()=>{
    navigate('/signin')
  }
  return (
    <Box sx={{ display: "flex" }}>
      <Button
        variant="contained"
        color="tertiary"
        onClick={handleClick}
        sx={{ borderRadius: "30px", mr: 2 }}
      >
        Se Connecter
      </Button>
      <LangSelector />
    </Box>
  );
};

export default Menu;
