import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { useContext } from "react";
import { useReactFlow } from "react-flow-renderer";
import accordionContext from "../../context/accordionContext";
import nodeContext from "../../context/nodeContext";
import { useChild } from "../../hooks/useChild";
import {
  changeLabelById,
  changeStatusModule,
  deselectAll,
  transitionNode,
} from "../../utils/nodes";
import CreateModules from "../Shared/CreateModules";
import GradientButton from "../Shared/GradientButton";

const CreateNodes = ({ modules, square }) => {
  const { fitView } = useReactFlow();
  const { setExpanded, ownerID, setOwnerStatus } = useContext(accordionContext);
  const { setNodes } = useContext(nodeContext);
  const [checkedList, setCheckedList] = useState(modules);
  const addChild = useChild(square);
  const handleValidate = () => {
    transitionNode(ownerID, setNodes);
    changeLabelById(ownerID, setNodes, "Lacus Industries");
    checkedList.map((child, index) => addChild(ownerID, child, index, checkedList));
    setExpanded(false);
    setOwnerStatus("executed");
    changeStatusModule(ownerID, "executed", setNodes);
    setTimeout(() => fitView(), 100);
    deselectAll(setNodes);
  };
  const cancel = () => {
    setExpanded(false);
    deselectAll(setNodes);
  };
  return (
    <Box sx={{ px: 2, pb: 2, display: "flex", alignItems: "flex-start", height: "40vh" }}>
      <CreateModules checkedList={checkedList} setCheckedList={setCheckedList} modules={modules} />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <GradientButton onClick={handleValidate} sx={{ mt: 1, width: "90px" }}>
          Valider
        </GradientButton>
        <Button
          onClick={cancel}
          variant="outlined"
          color="inherit"
          sx={{
            color: "rgba(0, 0, 0, 0.2)",
            mt: 1,
            width: "90px",
            borderRadius: "30px",
            border: "solid 3px",
            py: "10px",
            minWidth: "100px",
          }}
        >
          Annuler
        </Button>
      </Box>
    </Box>
  );
};

export default CreateNodes;
