import React from "react";
import { Routes as RoutesRouter, Route, useLocation } from "react-router-dom";
import BackgroundV from "./assets/images/BackgroundV";
import Navbar from "./components/Navbar";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import PrincipalMap from "./pages/PrinicipalMap";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp";

const Routes = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Navbar />
      {pathname !== "/map" && (
        <BackgroundV style={{ position: "absolute", bottom: 0, right: 0, zIndex: "-1" }} />
      )}
      <RoutesRouter>
        <Route path="/" element={<Home />} />
        <Route path="/map" element={<PrincipalMap />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
      </RoutesRouter>
    </>
  );
};

export { Routes };
