import React from "react";
import { Paper, styled } from "@mui/material";

const CenterPaper = (props) => {
  const { children, ...rest } = props;
  return <StyledPaper {...rest}>{children}</StyledPaper>;
};
const StyledPaper = styled(Paper)({
  position: "absolute",
  top: "50%",
  left: "50%",
  maxWidth: "95%",
  width: "500px",
  height: "500px",
  transform: "translate(-50%, -50%)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "30px",
});

export default CenterPaper;
