import { Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { CodeInput } from "../../components/CodeInput/CodeInput";
import GradientButton from "../../components/Shared/GradientButton";

const VerifyCodeStep = ({ handleNext }) => {
  const [code, setCode] = useState("");
  const handleChange = (str) => {
    setCode(str);
  };
  return (
    <>
      <Typography variant="h4" sx={{ mb: 1 }}>
        Le code de vérification
      </Typography>
      <Typography variant="subtitle1" sx={{ color: "#777", mb: 4 }}>
        Veuillez entrer le code à 6 chiffres
      </Typography>
      <CodeInput onChange={handleChange} />
      <GradientButton onClick={handleNext} sx={{ mt: 5 }}>
        Fait
      </GradientButton>
    </>
  );
};

export default VerifyCodeStep;
