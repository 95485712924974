import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Button, Menu, MenuItem, useTheme } from "@mui/material";
import "flag-icons/css/flag-icons.min.css";

const languages = [
  {
    code: "fr",
    name: "FR",
    country_code: "fr",
  },
  {
    code: "en",
    name: "EN",
    country_code: "gb",
  },
];

const LangSelector = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = (code) => {
    i18n.changeLanguage(code);
    setAnchorEl(null);
    if (props.onClose) {
      props.onClose();
    }
  };

  const currentLanguage = languages.find((elem) => elem.code === t("language"));

  return (
    <div {...props}>
      <Button
        variant="contained"
        color="primary"
        sx={{
          borderRadius: "30px",
          color: "white",
          background: "#14102D",
          "&:hover": {
            color: "white",
            background: "#14102D",
          },
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {currentLanguage && (
          <>
            <span
              style={{ marginRight: theme.spacing(1) }}
              // className={`fi fi-${currentLanguage.country_code}`}
            />
            {currentLanguage.name}
          </>
        )}
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        keepMounted
        elevation={0}
        getcontentanchorel={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#14102D",
            color: "white",
            marginTop: "2px",
            boxShadow: theme.shadows[4],
          },
        }}
        disableScrollLock
      >
        <MenuItem
          sx={{
            "&:hover": {
              backgroundColor: "white",
              color: "black",
            },
          }}
          onClick={() => handleClose("en")}
        >
          <span
          // className={`fi fi-gb`}
          />
          EN
        </MenuItem>
        <MenuItem
          sx={{
            "&:hover": {
              backgroundColor: "white",
              color: "black",
            },
          }}
          onClick={() => handleClose("fr")}
        >
          <span
          // className={`fi fi-fr`}
          />
          FR
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LangSelector;
