import * as React from "react";

const DotImg = (props) => (
  <svg width={17} height={11} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.221 10.02c-1.062-1.73 1.803-5.157 6.4-7.654C9.917.489 13.252.006 14.508 0c1.171.037 1.99.376 2.357.976.482 1.24-.03 4.506-6.399 7.654C5.868 11.13 1.283 11.75.221 10.02Z"
      fill="#F05673"
    />
  </svg>
);

export default DotImg;
