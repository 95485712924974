import "./App.css";
import {  CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./assets/theme";
import NodeContext from "./context/nodeContext";
import { useEdgesState, useNodesState } from "react-flow-renderer";
import EdgeContext from "./context/edgeContext";
import AccordionContext from "./context/accordionContext";
import { useState } from "react";
import { Routes } from "./Routes";
import { BrowserRouter } from "react-router-dom";
import InitialElements from "components/initial-elements";

function App() {
  const [initialNodes, initialEdges] = InitialElements();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [expanded, setExpanded] = useState(false);
  const [ownerID, setOwnerId] = useState("1");
  const [ownerStatus, setOwnerStatus] = useState("not-executed");
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <NodeContext.Provider value={{ nodes, setNodes, onNodesChange }}>
          <EdgeContext.Provider value={{ edges, setEdges, onEdgesChange }}>
            <AccordionContext.Provider
              value={{ expanded, setExpanded, ownerID, setOwnerId, ownerStatus, setOwnerStatus }}
            >
              <CssBaseline />
              <Routes />
            </AccordionContext.Provider>
          </EdgeContext.Provider>
        </NodeContext.Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
