import { Paper, Stack, Typography } from "@mui/material";
import accordionContext from "context/accordionContext";
import React, { useContext, useState } from "react";

const Panels = () => {
  const [showPanels, setShowPanels] = useState(false);
  const { ownerID, ownerStatus } = useContext(accordionContext);
  React.useEffect(() => {
    if (ownerStatus === "executed" && ownerID === "1-1-1-1-1-1") {
      setShowPanels(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerStatus === "executed" && ownerID === "1-1-1-1-1-1"]);
  if (showPanels)
    return (
      <Stack direction="column" sx={{ position: "absolute", top: "100px", right: "20px" }}>
        <Paper elevation={3} sx={{ borderRadius: "20px", p: 1, width: "100px", zIndex:11, height: "80px" }}>
          <Typography sx={{ fontWeight: "550" }} align="center" variant="h5">
            20%
          </Typography>
          <Typography align="center" color="primary" variant="h6">
            Maturité
          </Typography>
        </Paper>
        <Paper
          elevation={3}
          sx={{ mt: 1, borderRadius: "20px", p: 0.5, width: "100px", zIndex:11, height: "80px" }}
        >
          <Typography sx={{ fontWeight: "550" }} align="center" variant="h5">
            14
          </Typography>
          <Typography sx={{ my: -1 }} align="center" color="primary" variant="h6">
            Actions
          </Typography>
          <Typography sx={{ fontWeight: "550" }} align="center" variant="body1">
            100k $
          </Typography>
        </Paper>
      </Stack>
    );
  else return <></>;
};

export default Panels;
