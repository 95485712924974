import React, { useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useStoreApi } from "react-flow-renderer";
import edgeContext from "../../../context/edgeContext";
import nodeContext from "../../../context/nodeContext";
const AddBtn = ({ id, ...rest }) => {
  const store = useStoreApi();
  const { nodeInternals } = store.getState();
  const nodes = Array.from(nodeInternals).map(([, node]) => node);
  const childrenCounter = () => {
    const parentID = id;
    let filtredNode = nodes.filter((e) => {
      return (
        e.id.slice(0, parentID.length) === parentID &&
        parentID.split("-").length + 1 === e.id.split("-").length
      );
    });
    return filtredNode.length + 1;
  };
  const maxId = childrenCounter();
  const newId = String(id + "-" + maxId);
  const node = nodes.find((element) => element.id === id);
  const xNode = node?.position.x;
  const yNode = node?.position.y;
  const { setEdges } = useContext(edgeContext);
  const { setNodes } = useContext(nodeContext);
  const onAdd = () => {
    const newNode = {
      id: newId,
      type: "CustomNode",
      data: {
        id: newId,
        label: newId,
        bgColor: "#B7D3DF",
        width: "100px",
        height: "100px",
      },
      position: {
        x: xNode,
        y: yNode + 100,
      },
    };
    setNodes((nds) => nds.concat(newNode));
    const newEdge = { id: `${id}-${newId}`, source: id, target: newId, type: "step" };
    setEdges((edgs) => edgs.concat(newEdge));
  };

  return <AddIcon onClick={onAdd} {...rest} />;
};

export default AddBtn;
