import { Box, Typography } from "@mui/material";
import React from "react";
import GradientButton from "../../components/Shared/GradientButton";
import Input from "../../components/Shared/Input";
import UnderlinedTypography from "../../components/Shared/UnderlinedTypography";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import { useFormik } from "formik";
import * as Yup from "yup";

const SendMailStep = ({ handleNext }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("L'e-mail n'est pas valide").required("L’adresse e-mail est requise"),
    }),
    onSubmit: (values) => {
      handleNext();
    },
  });
  return (
    <>
      <Typography variant="h4" align="center" sx={{ fontWeight: "550" }}>
        Mot de passe
      </Typography>
      <UnderlinedTypography variant="h4" align={"center"} sx={{ mb: 2, mt: 0 }}>
        oublié
      </UnderlinedTypography>
      <Typography align={"center"} variant="h6" color="primary">
        Le code de vérification
      </Typography>
      <Typography align={"center"} variant="subtitle2" sx={{ color: "#9A9A9A", mb: 2 }}>
        Nous vous enverrons un code de vérification sur votre e-mail
      </Typography>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}
      >
        <Input
          elevation={0}
          required
          type="email"
          error={Boolean(formik.touched.email && formik.errors.email)}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}
          helperText={formik.touched.email && formik.errors.email}
          id="email"
          name="email"
          placeholder="E-mail"
          icon={AlternateEmailOutlinedIcon}
          sx={{ mb: 3 }}
        />
        <GradientButton sx={{ px: 2 }} type="submit">
          Envoyer le code de vérification
        </GradientButton>
      </Box>
    </>
  );
};

export default SendMailStep;
