import React, { useState } from "react";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import CreateNodesStep from "./CreateNodesStep";
import GrettingStep from "./GrettingStep";
import CreateNodeForm from "./FormStep";
import InfoStep from "./InfoStep";

export default function InitialContent() {
  const [activeStep, setActiveStep] = useState(0);
  const formik = useFormik({
    initialValues: {
      companyName: "",
      typeAccount: "",
      launchedYear: "",
      capital: "",
      industry: "",
      activity: "",
      employessNbre: "",
      tel: "",
      email: "",
      website: "",
    },
    onSubmit: (values) => {
      // dispatch(register(values));
    },
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: registerValidation,
  });

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <Box sx={{ height: "45vh" }}>
      {activeStep === 0 && <GrettingStep handleNext={handleNextStep} />}
      {activeStep === 1 && <InfoStep handleNext={handleNextStep} />}
      {activeStep === 2 && <CreateNodeForm formik={formik} handleNext={handleNextStep} />}
      {activeStep === 3 && <CreateNodesStep />}
    </Box>
  );
}
