import React, { memo, useContext, useEffect } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { Handle, useNodes } from "react-flow-renderer";
import "./style.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import accordionContext from "../../context/accordionContext";
import PeopleIcon from "@mui/icons-material/People";

export default memo(({ data, isConnectable, selected }) => {
  const nodes = useNodes();
  const { expanded, setExpanded, setOwnerId, setOwnerStatus } = useContext(accordionContext);
  const handleClick = () => {
    setExpanded(true);
    setOwnerId(data.id);
    setOwnerStatus(data.status);
  };
  useEffect(() => {
    if (!nodes.map((node) => node.selected).includes(true)) {
      setExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes]);
  return (
    <Box
      onClick={handleClick}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#333",
        width: data.width,
        height: data.height,
        borderRadius: data.borderRadius,
        background: data.bgColor,
        border: expanded && selected ? "3px solid white" : "none",
        outline: expanded && selected ? "1px solid " : "none",
      }}
    >
      <Handle
        type="target"
        position="top"
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      {data.percentage && (
        <Paper
          sx={{
            background: "#B7D3DF",
            width: "40px",
            height: "40px",
            position: "absolute",
            top: 0,
            right: 0,
            transform: "translate(45%, -40%)",
            borderRadius: "50%",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data.percentage >= 100 ? <CheckCircleOutlineIcon /> : data.percentage + "%"}
        </Paper>
      )}
      <Typography
        variant="body1"
        textAlign="center"
        sx={{ display: "flex", flexDirection: "column", alignItems: "center", color: "white" }}
      >
        {data?.icon && <PeopleIcon />}{" "}
        {data.label.length > 35 ? data.label.slice(0, 20) + "..." : data.label}
      </Typography>
      <Handle
        type="source"
        position="bottom"
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
    </Box>
  );
});
