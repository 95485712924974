import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#F05673",
      contrastText: "rgb(30,30,30)",
    },
    secondary: {
      main: "#9DBBC9",
      contrastText: "#ffffff",
    },
    tertiary: {
      main: "#14102D",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});
theme = responsiveFontSizes(theme);
export default theme;
