import { Box, Typography } from "@mui/material";
import ActionDetails from "components/ActionDetails";
import Result from "components/Result/Result";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import accordionContext from "../../context/accordionContext";
import CreateNodes from "../CreateNodes";
import DetailsModule from "../DetailsModule";
import QuestionModule from "../QuestionModule";
import InitialNodeContent from "../InitialNode";
import InfoStep from "components/InitialNode/InfoStep";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

const ModuleSteps = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { ownerID, ownerStatus } = useContext(accordionContext);
  let modules = [];
  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  switch (ownerID) {
    case "1": {
      modules = ["Maturité digitale", "Règlementation", "Marché", "Technologie"];
      if (ownerStatus === "not-executed") {
        return <InitialNodeContent />;
      }
      return <InfoStep />;
    }
    case "1-1":
      modules = [
        "Client",
        "Opérations",
        "Données",
        "Innovation",
        "Culture",
        "Valeur et concurrents",
      ];
      return (
        <Box>
          {activeStep === 0 && (
            <DetailsModule
              ModuleName={"Maturité digitale"}
              description={"Exécutez le module pour évaluer votre maturité digitale"}
              handleNext={modules.length > 0 ? handleNextStep : null}
            />
          )}
          {activeStep === 1 && ownerStatus !== "executed" && modules.length > 0 && (
            <CreateNodes modules={modules} />
          )}
        </Box>
      );
    case "1-1-1":
      modules = [
        "Canal du marketing digital",
        "Livraison d'expérience omnicanal",
        "Ventes activées par la technologie",
        "Automatisation du service client",
      ];
      return (
        <Box>
          {activeStep === 0 && (
            <DetailsModule
              ModuleName={"Client"}
              handleNext={modules.length > 0 ? handleNextStep : null}
            />
          )}
          {activeStep === 1 && ownerStatus !== "executed" && modules.length > 0 && (
            <CreateNodes modules={modules} />
          )}
        </Box>
      );
    case "1-1-1-1":
      modules = ["Ciblage intelligent des clients", "Contenu intelligent"];
      return (
        <Box>
          {activeStep === 0 && (
            <DetailsModule
              ModuleName={"Canal du marketing digital"}
              description={
                "Utilisation d'outils intelligents pour améliorer les processus de recherche, d'interaction et de dialogue avec les acheteurs potentiels"
              }
              handleNext={handleNextStep}
            />
          )}
          {activeStep === 1 && <QuestionModule modules={modules} />}
        </Box>
      );
    case "1-1-1-1-1":
      modules = [
        "Outils de ciblage et de positionnement de segmentation en ligne (Praxie)",
        "Processus STP",
      ];
      return (
        <Box>
          {activeStep === 0 && <ActionDetails handleNext={handleNextStep} />}
          {activeStep === 1 && <CreateNodes modules={modules} square />}
        </Box>
      );
    case "1-1-1-1-1-1":
      return <Result />;

    default:
      return (
        <Box>
          <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
            <ReportGmailerrorredIcon fontSize="large" color="primary" sx={{ mr: 1 }} /> Ce module
            n’est pas disponible dans cette version{" "}
          </Typography>
        </Box>
      );
  }

  // if (ownerID !== "1-1-1-1" && ownerID !== "1-1-1-1-1" && (ownerID !== "1-1-1-1-1-1"))
  //   return (
  //     <Box>
  //       {activeStep === 0 && (
  //         <DetailsModule handleNext={modules.length > 0 ? handleNextStep : null} />
  //       )}
  //       {activeStep === 1 && ownerStatus !== "executed" && modules.length > 0 && (
  //         <CreateNodes modules={modules} />
  //       )}
  //     </Box>
  //   );
  // else if (ownerID === "1-1-1-1-1") {
  //   return (
  //     <Box>
  //       {activeStep === 0 && <ActionDetails handleNext={handleNextStep} />}
  //       {activeStep === 1 && (
  //         <CreateNodes
  //           modules={[
  //             "Praxie's Online Segment...",
  //             "STP process",
  //           ]}
  //           square
  //         />
  //       )}
  //     </Box>
  //   );
  // } else if (ownerID === "1-1-1-1-1-1") {
  //   return <Result />;
  // } else
  //   return (
  //     <Box>
  //       {activeStep === 0 && <DetailsModule handleNext={handleNextStep} />}
  //       {activeStep === 1 && <QuestionModule modules={modules} />}
  //     </Box>
  //   );
};

export default ModuleSteps;
