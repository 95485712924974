import { FormControl, FormHelperText, InputBase, Paper, styled } from "@mui/material";
import React from "react";
import { useRef } from "react";

const Input = ({
  elevation,
  required,
  error,
  helperText,
  placeholder,
  icon: Icon,
  sx,
  ...rest
}) => {
  const inputRef = useRef();
  const focus = (e) => {
    e.preventDefault();
    inputRef.current.focus();
  };

  return (
    <FormControlStyled error={error} required={required} fullWidth sx={sx}>
      <CustomPaper
        onClick={focus}
        elevation={elevation}
        sx={{ border: error ? "1px solid red" : "1px solid #C7C8CA" }}
      >
        <Icon color="primary" sx={{ mx: 1 }} />
        <InputBase {...rest} inputRef={inputRef} placeholder={placeholder} sx={{ width: "100%" }} />
      </CustomPaper>

      {error && helperText && (
        <FormHelperText
          sx={{ textAlign: "center", position: "absolute", bottom: -22, right: 0, left: 0 }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControlStyled>
  );
};

export default Input;

Input.defaultProps = {
  elevation: 2,
};

const CustomPaper = styled(Paper)({
  padding: "4px",
  width: "100%",
  height: "50px",
  borderRadius: "30px",
  background: "white",
  display: "flex",
  alignItems: "center",
  border: "1px solid #C7C8CA",
  // marginBottom: "8px",
});

const FormControlStyled = styled(FormControl)({
  marginBottom: "8px",
});
