import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputBase,
  Typography,
} from "@mui/material";
import { React } from "react";
import GradientButton from "../Shared/GradientButton";

const CreateNodeForm = ({ formik, handleNext }) => {
  // const [type, setType] = useState("");
  // const handleChange = (event) => {
  //   setType(event.target.value);
  // };
  const types = ["PME", "Grand groupe", "Artisan", "Artiste", "Sportif"];
  const employeesNbr = ["0", "1-19", "20-50", "50-100", "100-500", "500<"];
  return (
    <Box
      sx={{
        px: 2,
        pb: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <form
        onSubmit={formik.handleSubmit}
        style={{ flex: 0.8, display: "flex", flexDirection: "column", alignItems: "flex-start" }}
      >
        <FormControlLabel
          sx={{ alignItems: "flex-start", ml: "unset" }}
          control={<InputBase color="primary" placeholder=" Saisir ici..." />}
          label={
            <Typography variant="h5" color="primary">
              Nom de la société
            </Typography>
          }
          labelPlacement="top"
        />

        <Grid container spacing={3}>
          <Grid
            item
            md={4}
            xs={12}
            sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
          >
            <CustomSelect label={"Type de Compte"} options={types} />
            <YearSelector />
            <Input label={"Capital"} variant="h6" />
          </Grid>
          <Grid item md={4} xs={12}>
            <Input label={"Activité"} variant="h6" />
            <Input label={"Sous-Activité"} variant="h6" />
            <CustomSelect label={"Nombre d'employés"} options={employeesNbr} />
          </Grid>
          <Grid item md={4} xs={12}>
            <Input label="Téléphone" variant="h6" />
            <Input label="Adresse email" variant="h6" />
            <Input label="Site Web" variant="h6" />
          </Grid>
        </Grid>
      </form>
      <GradientButton onClick={handleNext}>Suivant</GradientButton>
    </Box>
  );
};

const YearSelector = () => {
  const year = new Date().getFullYear();
  const years = Array.from(new Array(40), (val, index) => year - index);
  return <CustomSelect label={"Année de lancement"} options={years} />;
};
const Input = ({ label, variant }) => {
  return (
    <FormControlLabel
      sx={{ alignItems: "flex-start", ml: "unset", mt: 2 }}
      control={<InputBase color="primary" placeholder="Saisir ici..." />}
      label={
        <Typography variant={variant} color="primary" sx={{ fontWeight: "400" }}>
          {label}
        </Typography>
      }
      labelPlacement="top"
    />
  );
};

const CustomSelect = ({ label, options }) => {
  return (
    <FormControl fullWidth margin="normal">
      <Typography variant="h6" sx={{ fontWeight: "400" }} color="primary">
        {label}
      </Typography>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option}
        sx={{ flex: 1, display: "flex" }}
        // onChange={(e, value) => value && focusNode(value?.id)}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return <InputBase placeholder="Sélectionner.." {...params.InputProps} {...rest} />;
        }}
      />
    </FormControl>
  );
};
export default CreateNodeForm;
