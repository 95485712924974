const InitialElements = () => {
  const initialNodes = [
    {
      id: "1",
      type: "CustomNode",
      data: {
        id: "1",
        label: "Parlons de vous",
        bgColor: "#B7D3DF",
        width: "100px",
        height: "100px",
        borderRadius:'50%',
        status: "not-executed", //"executed||not-executed"
        // percentage: 90,
      },
      position: { x: -350, y: 180 },
    },
  ];

  const initialEdges = [];

  return [initialNodes, initialEdges];
};

export default InitialElements;
