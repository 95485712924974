import React, { memo, useContext, useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { Handle, useNodes } from "react-flow-renderer";
import "./style.css";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import AddBtn from "./OptionsNode/AddBtn";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PercentageBtn from "./OptionsNode/PercentageBtn";
import accordionContext from "../../context/accordionContext";

export default memo(({ data, isConnectable, selected }) => {
  const nodes = useNodes();
  const { setExpanded } = useContext(accordionContext);
  const [isHovered, setIsHovered] = useState(false);
  const toggleHover = () => {
    setIsHovered(!isHovered);
  };
  const calculSin = (angle) => {
    let width = parseInt(data.width.replace("px", ""));

    return (width / 2 + 15) * Math.sin(Math.PI / (360 / angle));
  };
  const calculCos = (angle) => {
    let width = parseInt(data.width.replace("px", ""));
    return (width / 2 + 15) * Math.cos(Math.PI / (360 / angle));
  };

  useEffect(() => {
    if (selected) {
      setExpanded(true);
    }
  }, [selected]);

  useEffect(() => {
    if (!nodes.map((node) => node.selected).includes(true)) {
      setExpanded(false);
    }
  }, [nodes]);
  return (
    <Box
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#333",
        width: data.width,
        height: data.height,
        borderRadius: "50%",
        background: data.bgColor,
        // outline: isHovered ? "5px solid #F5F5F5" : "",
        border: selected ? "3px solid white" : "none",
        outline: selected ? "1px solid " : "none",
      }}
    >
      <Handle
        type="target"
        position="top"
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      {data.percentage && (
        <Paper
          sx={{
            background: "#B7D3DF",
            width: "40px",
            height: "40px",
            position: "absolute",
            top: 0,
            right: 0,
            transform: "translate(45%, -40%)",
            borderRadius: "50%",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data.percentage >= 100 ? <CheckCircleOutlineIcon /> : data.percentage + "%"}
        </Paper>
      )}
      <PlayCircleOutlineIcon
        onClick={() => console.log("hhi")}
        sx={{
          display: isHovered ? "block" : "none",
          cursor: "pointer",
          width: "15px",
          height: "15px",
          position: "absolute",
          transform: `translate(${-calculCos(30) + "px"}, ${calculSin(30) + "px"}) `,
        }}
      />
      <PercentageBtn
        id={data.id}
        sx={{
          display: isHovered ? "block" : "none",
          cursor: "pointer",
          width: "15px",
          height: "15px",
          position: "absolute",
          transform: `translate(${-calculCos(90) + "px"}, ${calculSin(90) + "px"}) `,
        }}
      />
      <AddBtn
        id={data.id}
        sx={{
          display: isHovered ? "block" : "none",
          cursor: "pointer",
          width: "15px",
          height: "15px",
          position: "absolute",
          transform: `translate(${-calculCos(150) + "px"}, ${calculSin(150) + "px"}) `,
        }}
      />
      <Typography variant="body1" textAlign="center" sx={{ color: "white" }}>
        {data.label}
      </Typography>
      <Box component="ul" className="circle" sx={{ display: isHovered ? "block" : "none" }}>
        <Box component="li" className="li-btn li-btn1" />
        <Box component="li" className="li-btn li-btn2" />
        <Box component="li" className="li-btn li-btn3" />
      </Box>
      <Handle
        type="source"
        position="bottom"
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
    </Box>
  );
});
