import * as React from "react";
import { Box, Paper } from "@mui/material";
import ModuleSteps from "../ModuleSteps/ModuleSteps";

export const ContentPlaceholder = () => {
  return (
    <Box sx={{ transformOrigin: "top center" }}>
      <Paper
        sx={{
          width: "100%",
          borderRadius: "0px",
          display: "inline-block",
          background: "#fff",
          p: 2,
        }}
      >
        <ModuleSteps />
      </Paper>
    </Box>
  );
};
