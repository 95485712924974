import * as React from "react";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import check from "../../assets/images/check.png";

const BpIcon = styled("span")({
  borderRadius: 7,
  width: "22px",
  height: "22px",
  boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto #9A9A9A",
    outlineOffset: 5,
  },
  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
});

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#f5f8fa",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    content: '""',
    display: "block",
    width: "30px",
    height: "25px",
    zIndex: "11",
    backgroundImage: `url(${check})`,
    backgroundRepeat: "no-repeat",
  },
  "input:hover ~ &": {
    backgroundColor: "#white",
  },
});

function CustomizedCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export default CustomizedCheckbox;
