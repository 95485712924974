import { Avatar, Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import GradientButton from "../Shared/GradientButton";

const InfoStep = ({ handleNext, ...rest }) => {
  return (
    <Box
      sx={{
        height: "100%",
        px: 2,
        pb: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-end",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Container maxWidth="lg">
          <Grid container rowSpacing={5} columnSpacing={1}>
            <InformationCard title={"Nom de l'entreprise"} label={"Lacus Industries"} />
            <InformationCard title={"Téléphone"} label={"03 31 39 09 59"} />
            <InformationCard title={" Domaine d'activité"} label={"Agroalimentaire "} />
            <InformationCard title={"Adresse email"} label={"contact@lacus.com"} />
            <InformationCard title={" Pays"} label={"France"} />
            <InformationCard title={"Site webays"} label={"www.lacus.com"} />
          </Grid>
        </Container>
        {handleNext && <GradientButton onClick={handleNext}>Suivant</GradientButton>}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          alt="Remy Sharp"
          sx={{ width: 24, height: 24, mr: 1 }}
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Typography variant="caption">Dernière exécution le 01/11/2022 à 10h30</Typography>
      </Box>
    </Box>
  );
};

const InformationCard = ({ title, label }) => {
  return (
    <Grid item md={6} xs={12}>
      <Typography variant="h6" sx={{ fontWeight: "400" }} color="primary">
        {title}
      </Typography>
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        {label}
      </Typography>
    </Grid>
  );
};
export default InfoStep;
