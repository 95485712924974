import { useCallback } from "react";
import { useStore } from "react-flow-renderer";

import { getEdgeParams } from "../../../utils/nodes";

function FloatingEdge({ id, source, target, markerEnd, style }) {
  const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

  return (
    <path
      id={id}
      className="react-flow__edge-path"
      d={`M ${sx},${sy}L ${tx},${ty}`}
      markerEnd={markerEnd}
      style={{ style }}
    />
  );
}

export default FloatingEdge;
