import { Avatar, Box, Container, Typography } from "@mui/material";
import GradientButton from "components/Shared/GradientButton";
import accordionContext from "context/accordionContext";
import React, { useContext } from "react";

const ActionDetails = ({ handleNext }) => {
  const { ownerStatus } = useContext(accordionContext);
  return (
    <Box
      sx={{
        height: "45vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-end",
        px: 3,
        pb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        >
          <Typography variant="h4" sx={{ mb: 8 }}>
            Ciblage intelligent des clients
          </Typography>
          <Typography align="center" variant="h6" sx={{ fontWeight: "400" }}>
            Utilisation des données clients existantes, des rapports de marché et des analyses
            avancées pour déterminer les tactiques de vente incitative aux clients existants et les
            stratégies d'extension à de nouveaux segments
          </Typography>
        </Container>
        {ownerStatus !== "executed" && handleNext && (
          <GradientButton onClick={handleNext}>Lancer</GradientButton>
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          alt="Remy Sharp"
          sx={{ width: 24, height: 24, mr: 1 }}
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Typography variant="caption">Dernière exécution le 01/11/2022 à 10h30</Typography>
      </Box>
    </Box>
  );
};

export default ActionDetails;
