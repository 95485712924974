import React, { useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import GroupOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import mapImg from "../../assets/images/mapHome.png";
import Register from "./Register";
import { motion, AnimatePresence } from "framer-motion";
import UnderlinedTypography from "../../components/Shared/UnderlinedTypography";

const Home = () => {
  const [registerType, setRegisterType] = useState("");
  const handleClick = (type) => {
    setRegisterType(type);
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 15, mb: 5 }}>
      <Grid container spacing={5}>
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <HeaderTitle />
          <Typography variant="h6" color="primary" sx={{ fontWeight: "550", mb: 4 }}>
            L'outil le plus puissant d'évaluation digitale
          </Typography>
          <Typography variant="body2" sx={{ color: "#9A9A9A", mb: 4 }}>
            Digital2Value est une plateforme engageant un pool de consultants certifiés pour
            accompagner les PME dans la conception et la mise en œuvre de leur parcours de
            transformation digitale, en s'appuyant sur un cadre et des outils de transformation
            numérique de classe mondiale.
          </Typography>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Commencez votre transformation en nous rejoignant
          </Typography>
          <Typography variant="body1" sx={{ color: "#5B5B5B", fontWeight: "550" }}>
            Vous êtes ?
          </Typography>
          <Box>
            <BtnCustom onClick={() => handleClick("consultant")}>
              <PersonOutlineOutlinedIcon color="primary" sx={{ mr: 0.5 }} />
              consultant
            </BtnCustom>
            <BtnCustom onClick={() => handleClick("componay")}>
              <GroupOutlinedIcon color="primary" sx={{ mr: 0.5 }} />
              Entreprise
            </BtnCustom>
          </Box>
        </Grid>
        <Grid item sm={12} md={6}>
          {registerType && (
            <AnimatePresence mode="wait">
              <Box
                key={registerType}
                component={motion.section}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ display: "none", opacity: 0 }}
                transition={{ duration: 1.9, ease: [0.25, 0.62, 0.23, 0.98] }}
              >
                <Register isCompany={registerType === "componay"} />
              </Box>
            </AnimatePresence>
          )}
        </Grid>
      </Grid>
      <Box sx={{ display: { md: "block", xs: "none" } }}>
        {!registerType && (
          <img
            alt="map home"
            src={mapImg}
            style={{
              objectFit: "contain",
              position: "absolute",
              bottom: "5%",
              right: 0,
              height: "90%",
              zIndex: "100",
            }}
          />
        )}
      </Box>
    </Container>
  );
};

export default Home;

const HeaderTitle = () => {
  return (
    <Typography variant="h3" sx={{ fontWeight: "550", mb: 2 }}>
      Entrer dans l'univers de la transformation{" "}
      <UnderlinedTypography component="span" variant="h3">
        digitale
      </UnderlinedTypography>
    </Typography>
  );
};

const BtnCustom = ({ children, ...rest }) => {
  return (
    <Button
      {...rest}
      variant="outlined"
      color="inherit"
      sx={{
        mr: 2,
        background: "#E6E7E8",
        mt: 1,
        borderRadius: "30px",
        border: "none",
        py: "10px",
        minWidth: "100px",
        "&:hover": {
          background: "#14102D",
          color: "white",
        },
      }}
    >
      {children}
    </Button>
  );
};
