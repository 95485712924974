import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import Input from "../../components/Shared/Input";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import GradientButton from "../../components/Shared/GradientButton";
import Check from "../../assets/icons/Check";
import UnderlinedTypography from "../../components/Shared/UnderlinedTypography";
import CenterPaper from "../../components/Shared/CenterPaper";
import { useFormik } from "formik";
import * as Yup from "yup";

const SignUp = () => {
  const [verify, setVerify] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("L'e-mail n'est pas valide").required("L'e-mail est requis"),
      password: Yup.string()
        .min(6, "le mot de passe doit faire entre 6 et 20 caractères")
        .max(20, "le mot de passe doit faire entre 6 et 20 caractères")
        .required("Le mot de passe est requis"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });
  return (
    <CenterPaper elevation={4}>
      {!verify ? (
        <>
          <UnderlinedTypography variant="h4" align={"center"}>
            S’inscrire
          </UnderlinedTypography>
          <Typography align={"center"} variant="subtitle1" color="primary">
            Veuillez vérifier, Nous avons envoyé un code
          </Typography>
          <Typography align={"center"} variant="subtitle2" sx={{ color: "#9A9A9A", mb: 2 }}>
            il ne nous manque que votre confirmation
          </Typography>
          <Box component="form" sx={{ width: "80%" }}>
            <Input
              elevation={0}
              placeholder={"Veuillez entrer le code"}
              icon={KeyOutlinedIcon}
              sx={{ mb: 2 }}
            />
            <Input
              elevation={0}
              placeholder={"Mot de passe"}
              icon={LockOpenOutlinedIcon}
              sx={{ mb: 2 }}
            />
            <Input
              elevation={0}
              placeholder={"Confirmez le mot de passe"}
              icon={LockOpenOutlinedIcon}
              sx={{ mb: 3 }}
            />
          </Box>
          <GradientButton onClick={() => setVerify(true)}>valider</GradientButton>
        </>
      ) : (
        <Validate />
      )}
    </CenterPaper>
  );
};

export default SignUp;

const Validate = () => {
  return (
    <>
      <UnderlinedTypography variant="h4" align={"center"}>
        Félicitations!
      </UnderlinedTypography>
      <Box sx={{ width: "150px", height: "150px", mb: 2 }}>
        <Check />
      </Box>
      <Typography align={"center"} variant="subtitle1" color="primary">
        Votre compte a été créé avec succès
      </Typography>
      <Typography align={"center"} variant="subtitle2" sx={{ color: "#9A9A9A", mb: 2 }}>
        Connectez pour accéder à votre compte
      </Typography>
      <GradientButton sx={{ px: 2 }}>Connecter</GradientButton>
    </>
  );
};
