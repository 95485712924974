import React, { useState } from "react";
import { ClickAwayListener, Paper, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AnimatePresence, motion } from "framer-motion";

const HeadQuestion = ({ activePage }) => {
  console.log(activePage);
  const title = [
    "Ciblage intelligent des clients",
    "Catalogue électronique et matériel",
    "Marketing à base de déclencheur",
  ];
  const Tooltip = [
    "Utilisation des données clients existantes, des rapports de marché et des analyses avancées pour déterminer les tactiques de vente incitative aux clients existants et les stratégies d'extension à de nouveaux segments",
    "Les catalogues de produits sont disponibles pour les clients via des canaux numériques qui permettent un accès détaillé à tous les modèles, fonctionnalités et modules complémentaires.",
    "Communications marketing basées sur la mesure des changements pertinents et identifiables dans les habitudes et les tendances d'achat d'un acheteur",
  ];

  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Typography variant="h4" sx={{ display: "flex", alignItems: "center", position: "relative" }}>
      <InfoOutlinedIcon
        onClick={handleOpen}
        color="primary"
        fontSize="unset"
        sx={{ mr: 3, cursor: "pointer" }}
      />
      {title[activePage]}
      <AnimatePresence mode="wait">
        {isOpen && (
          <ClickAwayListener onClickAway={handleClose}>
            <Paper
              onClick={handleClose}
              elevation={5}
              key={isOpen}
              component={motion.section}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, ease: [0.25, 0.62, 0.23, 0.98] }}
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: 0,
                right: 0,
                display: "flex",
                alignItems: "center",
                width: "600px",
                p: 1,
                borderRadius: "10px",
                background: "white",
                zIndex: "1",
              }}
            >
              <InfoOutlinedIcon color="primary" fontSize="unset" sx={{ mr: 1 }} />
              <Typography variant="body2">{Tooltip[activePage]} </Typography>
            </Paper>
          </ClickAwayListener>
        )}
      </AnimatePresence>
    </Typography>
  );
};

export default HeadQuestion;
