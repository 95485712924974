import * as React from "react"

const LineImg = (props) => (
  <svg
    width={30}
    height={2}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path stroke="#EB245F" strokeWidth={2} d="M0 1h30" />
  </svg>
)

export default LineImg
