export const questions = [
  {
    question:
      "Quelle partie des décisions de ciblage client est prise à l'aide de processus intelligents ?",
    response: [
      "Aucune utilisation de processus intelligents pour prendre des décisions de ciblage client, qui sont généralement prises en utilisant des données historiques",
      "Les décisions de ciblage intelligent des clients sont prises avec un décalage important, en utilisant des programmes tiers ou en envoyant des données hors site.  Les résultats du ciblage client doivent être intégrés manuellement dans les systèmes de marketing internes",
      "Les processus intelligents guident <50% des décisions de ciblage client Il existe une capacité en temps réel et une intégration avec les systèmes de marketing Prise en charge du lecteur d'écran activée ",
      "Les systèmes intelligents guident la majorité (> 75%) des décisions de ciblage des clients, qui peuvent être prises en temps réel. Les programmes de ciblage client sont bien intégrés dans d'autres processus marketing, permettant une action directe après les décisions de ciblage",
    ],
  },
  {
    question:
      "Dans quelle mesure les documents électroniques sont-ils utilisés pour présenter les options et la disponibilité des produits aux acheteurs potentiels ?",
    response: [
      "Utilisation exclusive des catalogues de produits imprimés et des listes de stock envoyés aux acheteurs potentiels par courrier traditionnel",
      "Combinaison de catalogues imprimés envoyés par courrier et de listes électroniques accessibles via un site Web traditionne",
      "Les sites Web traditionnels sont améliorés au-delà des formats de liste de produits génériques, mais les expériences ne sont toujours pas personnalisées Les communications électroniques telles que les listes de stock par courrier électronique sont génériques pour tous les clients.",
      "Mise en œuvre de nouvelles formes de médias numériques à l'aide notamment de systèmes automatisés générant des catalogues personnalisés et directement envoyés par courrier électronique à des acheteurs potentiels Les catalogues en ligne offrent des fonctionnalités de visualisation dynamique des produits, fonctionnalités et add-ons.",
    ],
  },
  {
    question:
      "Comment utilisez-vous les changements dans les comportements et les besoins des clients pour générer des communications marketing ?",

    response: [
      "Impossible de générer des communications basées sur des déclencheurs",
      "Une certaine capacité à générer des communications déclenchées pour des comportements limités et spécifiques (par exemple, la navigation, l’ajout d’articles au panier, etc.) Pas de suivi des changements dans les besoins des clients.",
      "Possibilité de générer des messages déclenchés en fonction de l'évolution des besoins du client (exp : cycles de développement de produit, historique des achats et demande d'échantillons), ainsi que pour un ensemble limité de comportements des clients.",
      "Capacité à générer des communications déclenchées pour répondre aux besoins du client et à adopter de nombreux comportements, y compris divers comportements de navigation sur le site Web, en se basant sur les schémas de réponse aux communications précédentes",
    ],
  },
];
