import { useContext } from "react";
import edgeContext from "../context/edgeContext";
import nodeContext from "../context/nodeContext";

export const useChild = (square) => {
  const { setEdges } = useContext(edgeContext);
  const { nodes, setNodes } = useContext(nodeContext);
  const childrenCounter = (id) => {
    const parentID = id;
    let filtredNode = nodes.filter((e) => {
      return (
        e.id.slice(0, parentID.length) === parentID &&
        parentID.split("-").length + 1 === e.id.split("-").length
      );
    });
    return filtredNode.length + 1;
  };
  const addChild = (id, childLabel, index, checkedList) => {
    const maxId = childrenCounter(id);
    const newId = String(id + "-" + (maxId + index));
    const node = nodes.find((element) => element.id === id);
    const xNode = node?.position.x;
    const yNode = node?.position.y;
    const circleRadius = node?.width;
    const newNode = {
      id: newId,
      type: "CustomNode",
      data: {
        id: newId,
        label: childLabel,
        borderRadius: square ? "15px" : "50%",
        bgColor: square ? "#81cab9" : "#B7D3DF",
        width: "100px",
        height: "100px",
        status: "not-executed",
      },
      position: {
        x: xNode + 2 * circleRadius * Math.cos((Math.PI / (checkedList.length / 2)) * index),
        y: yNode + 2 * circleRadius * Math.sin((Math.PI / (checkedList.length / 2)) * index),
      },
    };
    setNodes((nds) => nds.concat(newNode));
    const newEdge = { id: `${id}-${newId}`, source: id, target: newId, type: "floating" };
    setEdges((edgs) => edgs.concat(newEdge));
  };
  return addChild;
};
