import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";

const Answer = ({
  text,
  globalTarget,
  globalActual,
  setGlobalTarget,
  setGlobalActual,
  activePage,
}) => {
  const handleActual = () => {
    if (!globalActual.includes(activePage + "-" + text)) {
      setGlobalActual([
        ...globalActual.filter((elem) => !elem.startsWith(activePage + "-")),
        activePage + "-" + text,
      ]);
    } else {
      setGlobalActual(globalActual.filter((elem) => elem !== activePage + "-" + text));
    }
  };
  const handleTarget = () => {
    if (!globalTarget.includes(activePage + "-" + text)) {
      setGlobalTarget([
        ...globalTarget.filter((elem) => !elem.startsWith(activePage + "-")),
        activePage + "-" + text,
      ]);
    } else {
      setGlobalTarget(globalTarget.filter((elem) => elem !== activePage + "-" + text));
    }
  };
  return (
    <Box
      sx={{ mt: 3.5, display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}
    >
      <Typography variant="body1">{text}</Typography>
      <Box sx={{ ml: 1, display: "flex" }}>
        <Square
          disableElevation
          isActive={globalActual.includes(activePage + "-" + text)}
          onClick={handleActual}
          color="secondary"
          variant="contained"
          sx={{ mr: 2 }}
        >
          Actuel
        </Square>
        <Square
          disableElevation
          isActive={globalTarget.includes(activePage + "-" + text)}
          onClick={handleTarget}
          variant="contained"
        >
          Cible
        </Square>
      </Box>
    </Box>
  );
};

const Square = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ isActive }) => ({
  backgroundColor: isActive ? "" : "#DDDCDF",
  borderRadius: "10px",
  width: "50px",
  height: "50px",
  minWidth: "unset",
  fontSize: "12px",
  color: isActive ? "white" : "#B5B5B6",
  ":hover": {
    color: "white",
  },
}));
export default Answer;
