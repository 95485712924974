import React, { useContext, useState } from "react";
import {
  Avatar,
  Box,
  ClickAwayListener,
  Container,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AnimatePresence, motion } from "framer-motion";
import GradientButton from "components/Shared/GradientButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import accordionContext from "context/accordionContext";
const Result = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { setExpanded, setOwnerStatus } = useContext(accordionContext);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleClick = () => {
    setOwnerStatus("executed");
    setExpanded(false);
  };
  return (
    <Container
      sx={{
        height: "45vh",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      maxWidth="lg"
    >
      <Stack direction={"row"} justifyContent="space-between">
        <Typography
          variant="h5"
          sx={{ display: "flex", alignItems: "flex-start", position: "relative" }}
        >
          <InfoOutlinedIcon
            onClick={handleOpen}
            color="primary"
            fontSize="unset"
            sx={{ mr: 3, cursor: "pointer" }}
          />
          Outils de ciblage et de positionnement de segmentation en ligne (Praxie)
          <AnimatePresence mode="wait">
            {isOpen && (
              <ClickAwayListener onClickAway={handleClose}>
                <Paper
                  onClick={handleClose}
                  elevation={5}
                  key={isOpen}
                  component={motion.section}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3, ease: [0.25, 0.62, 0.23, 0.98] }}
                  sx={{
                    cursor: "pointer",
                    position: "absolute",
                    top: 2,
                    right: 0,
                    display: "flex",
                    alignItems: "center",
                    // width: "600px",
                    p: 1,
                    borderRadius: "10px",
                    background: "white",
                    zIndex: "1",
                  }}
                >
                  <InfoOutlinedIcon color="primary" fontSize="unset" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    Segmentation Targeting and Positioning Model techniques, Praxie’s online STP
                    Model collaboration tools allow any team or organization to instantly begin
                    working with our web templates and input forms. Our digital platform goes far
                    beyond other software tools by including progress dashboards, data integration
                    from existing documents or other SaaS software, elegant intuitive designs, and
                    full access on any desktop or mobile device.
                  </Typography>
                </Paper>
              </ClickAwayListener>
            )}
          </AnimatePresence>
        </Typography>
        <GradientButton
          onClick={handleClick}
          sx={{
            width: "50px",
            height: "50px",
            minWidth: "unset",
            mr: 2,
          }}
        >
          <NavigateNextIcon sx={{ display: "flex", alignItems: "center" }} />
        </GradientButton>
      </Stack>
      <Stack
        direction={"row"}
        divider={<Divider orientation="vertical" flexItem />}
        spacing={5}
        justifyContent="center"
        sx={{ mt: 4 }}
      >
        <Box>
          <Typography color="primary" variant="h5">
            Catégorie
          </Typography>
          <Typography color="secondary" variant="h6">
            Solutions
          </Typography>
          <Typography sx={{ mt: 4 }} color="primary" variant="h5">
            Date de début
          </Typography>
          <Typography color="secondary" variant="h6">
            12/12/2022
          </Typography>
        </Box>
        <Box>
          <Typography color="primary" variant="h5">
            Budget
          </Typography>
          <Typography color="secondary" variant="h6">
            20€ / mois
          </Typography>
          <Typography sx={{ mt: 4 }} color="primary" variant="h5">
            Date de fin
          </Typography>
          <Typography color="secondary" variant="h6">
            22/12/2022
          </Typography>
        </Box>
      </Stack>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        <Avatar
          alt="Remy Sharp"
          sx={{ width: 24, height: 24, mr: 1 }}
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Typography variant="caption">Dernière exécution le 01/11/2022 à 10h30</Typography>
      </Box>
    </Container>
  );
};

export default Result;
