import React, { useCallback, useContext, useState } from "react";
import ReactFlow, { addEdge, Controls, useReactFlow } from "react-flow-renderer";
import NodePercentage from "../../../components/CustomNode/NodePercentage";
import NodeEditable from "../../../components/CustomNode/NodeEditable";
import nodeContext from "../../../context/nodeContext";
import edgeContext from "../../../context/edgeContext";
import CustomNode from "../../../components/CustomNode/CustomNode";
import { Box, Button } from "@mui/material";
import Accordion from "../../../components/Accordion/Accordion";
import RestoreIcon from "@mui/icons-material/Restore";
import SaveIcon from "@mui/icons-material/Save";
import Background from "../../../assets/images/Background.png";
import FloatingEdge from "./FloatingEdge";
import Popup from "components/Popup/Popup";
import Panels from "components/Panels";

const flowKey = "map-flow";

const edgeTypes = {
  floating: FloatingEdge,
};

const nodeTypes = {
  nodePercentage: NodePercentage,
  NodeEditable: NodeEditable,
  CustomNode: CustomNode,
};
const MapFlow = () => {
  const { nodes, setNodes, onNodesChange } = useContext(nodeContext);
  const { edges, setEdges, onEdgesChange } = useContext(edgeContext);
  const [rfInstance, setRfInstance] = useState(null);
  const { setViewport } = useReactFlow();
  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);
  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      localStorage.setItem(flowKey, JSON.stringify(flow));
    }
  }, [rfInstance]);

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const flow = JSON.parse(localStorage.getItem(flowKey));

      if (flow) {
        const { x = 0, y = 0, zoom = 1 } = flow.viewport;
        setNodes(flow.nodes || []);
        setEdges(flow.edges || []);
        setViewport({ x, y, zoom });
      }
    };
    restoreFlow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNodes, setViewport]);

  return (
    <Box
      style={{
        width: "100vw",
        height: "100vh",
        background: `url(${Background}) center no-repeat rgba(230,230,230,0.4)`,
      }}
    >
      {/* <Popup /> */}
      <Panels/>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onInit={setRfInstance}
        fitView
      >
        <Controls />
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            right: "10px",
            bottom: "10px",
            zIndex: "4",
          }}
        >
          <Button variant="contained" sx={{ color: "white" }} onClick={onSave}>
            <SaveIcon />
          </Button>
          <Button variant="contained" sx={{ color: "white" }} onClick={onRestore}>
            <RestoreIcon />
          </Button>
        </Box> */}
      </ReactFlow>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          width: "90%",
          maxHeight: "90vh",
          margin: "0px auto",
          zIndex: 200,
          borderTopRightRadius: "15px",
          borderTopLeftRadius: "15px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Accordion />
      </Box>
    </Box>
  );
};

export default MapFlow;
