import React from "react";
import { styled, Typography } from "@mui/material";
import LineGradient from "../../assets/images/LineGradient.png";

const UnderlinedTypography = (props) => {
  const { children, ...rest } = props;
  return <StyledTypography {...rest}>{children}</StyledTypography>;
};

const StyledTypography = styled(Typography)({
  fontWeight: "550",
  marginTop: "16px",
  marginBottom: "12px",
  "&:after": {
    content: '""',
    display: "block",
    width: "200px",
    height: "30px",
    zIndex: "11",
    backgroundImage: `url(${LineGradient})`,
    backgroundRepeat: "no-repeat",
  },
});

export default UnderlinedTypography;
