import React from "react";
import { useStoreApi, useReactFlow } from "react-flow-renderer";
import { Paper, Autocomplete, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
const Search = () => {
  const store = useStoreApi();
  const { setCenter } = useReactFlow();
  const { nodeInternals } = store.getState();
  const nodes = Array.from(nodeInternals).map(([, node]) => node);
  const focusNode = (id) => {
    if (nodes.length > 0) {
      const node = nodes.find((element) => element.id === id);
      const x = node.position.x + node.width / 2;
      const y = node.position.y + node.height / 2;
      const zoom = 1.85;

      setCenter(x, y, { zoom, duration: 1000 });
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <Paper
      component="form"
      elevation={0}
      sx={{
        background: "#fff",
        borderRadius: "15px",
        display: "flex",
        overflow: "hidden",
        width: "300px",
        zIndex: "1000",
        alignItems: "center",
        justifyContent: "center",
      }}
      onSubmit={handleSubmit}
    >
      <SearchIcon sx={{ color: "#B6B6B6" }} />
      <Autocomplete
        forcePopupIcon={false}
        options={nodes}
        getOptionLabel={(option) => option.data.label}
        PaperComponent={CustomPaper}
        sx={{ px: 1, flex: 1, display: "flex" }}
        onChange={(e, value) => value && focusNode(value?.id)}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return <InputBase placeholder=" Search ..." {...params.InputProps} {...rest} />;
        }}
      />
    </Paper>
  );
};
const CustomPaper = (props) => {
  return <Paper elevation={0} sx={{ mt: -0.5, ml: -2 }} {...props} />;
};
export default Search;
