import { Box, Typography } from "@mui/material";
import React from "react";
import GradientButton from "../Shared/GradientButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CheckIcon from "@mui/icons-material/Check";

const FooterQuestion = ({ handleValidate, activePage, setActivePage, questions }) => {
  const handleNext = () => {
    if (questions.length !== activePage) {
      setActivePage(activePage + 1);
    }
  };
  const handlePrev = () => {
    if (activePage + 1 !== 1) {
      setActivePage(activePage - 1);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mt: 2,
        mb: 1,
        position: "relative",
      }}
    >
      {questions.length === activePage ? (
        <Box />
      ) : (
        <GradientButton sx={{ width: "50px", height: "50px", minWidth: "unset" }}>
          {activePage + 1}/{questions.length}
        </GradientButton>
      )}
      <Typography
        variant="h6"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        Canal du marketing digital
      </Typography>
      <Box>
        {activePage !== 0 && (
          <GradientButton
            onClick={handlePrev}
            sx={{
              width: "50px",
              height: "50px",
              minWidth: "unset",
              mr: 2,
            }}
          >
            <NavigateBeforeIcon sx={{ display: "flex", alignItems: "center" }} />
          </GradientButton>
        )}
        <GradientButton
          onClick={activePage === questions.length ? handleValidate : handleNext}
          sx={{ width: "50px", height: "50px", minWidth: "unset" }}
        >
          {activePage === questions.length ? (
            <CheckIcon sx={{ display: "flex", alignItems: "center" }} />
          ) : (
            <NavigateNextIcon sx={{ display: "flex", alignItems: "center" }} />
          )}
        </GradientButton>
      </Box>
    </Box>
  );
};

export default FooterQuestion;
