import * as React from "react";

const BackgroundV = (props) => (
  <svg width={681} height={796} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 0h36.582l282.665 750.41L598.592 0h36.579L335.876 796h-36.578L0 0Zm339.2 620.665-16.629 45.587L73.161 0h36.581L339.2 620.665Zm36.575-94.675-16.626 49.092L142.996 0h33.255l199.524 525.99Zm33.261-94.679-16.629 49.096L212.833 0h36.578l159.625 431.311ZM668.419 0H705L405.706 796h-36.579L668.419 0Zm69.839 0h36.582L475.546 796h-33.255L738.258 0Zm73.16 0H848L548.706 796h-36.582L811.418 0Z"
      fill="url(#av)"
      fillOpacity={0.2}
    />
    <path
      d="M0 0h36.582l282.665 750.41L598.592 0h36.579L335.876 796h-36.578L0 0Zm339.2 620.665-16.629 45.587L73.161 0h36.581L339.2 620.665Zm36.575-94.675-16.626 49.092L142.996 0h33.255l199.524 525.99Zm33.261-94.679-16.629 49.096L212.833 0h36.578l159.625 431.311ZM668.419 0H705L405.706 796h-36.579L668.419 0Zm69.839 0h36.582L475.546 796h-33.255L738.258 0Zm73.16 0H848L548.706 796h-36.582L811.418 0Z"
      fill="url(#bv)"
    />
    <defs>
      <linearGradient
        id="av"
        x1={0.997}
        y1={398.035}
        x2={845.546}
        y2={398.035}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#14102D" />
        <stop offset={1} stopColor="#F05673" />
      </linearGradient>
      <linearGradient id="bv" x1={424} y1={0} x2={424} y2={796} gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={0.247} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

export default BackgroundV;
