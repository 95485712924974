import React, { useState } from "react";
import SendMailStep from "./SendMailStep";
import VerifyCodeStep from "./VerifyCodeStep";
import ResetPwdStep from "./ResetPwdStep";
import CenterPaper from "../../components/Shared/CenterPaper";

const ForgotPassword = () => {
  const [activeStep, setActiveStep] = useState(0);
  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };
  return (
    <CenterPaper elevation={4} sx={{ pt: 7, px: 4 }}>
      {activeStep === 0 && <SendMailStep handleNext={handleNextStep} />}
      {activeStep === 1 && <VerifyCodeStep handleNext={handleNextStep} />}
      {activeStep === 2 && <ResetPwdStep handleNext={handleNextStep} />}
    </CenterPaper>
  );
};


export default ForgotPassword;
