import React, { useState } from "react";
import { Box, Button, Divider, Stack, styled, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AnimatePresence, motion } from "framer-motion";

const ImportanceBtn = ({importance, setImportance}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleClick = (str) => {
    setImportance(str);
  };
  return (
    <Box sx={{ position: "relative" }}>
      <CustomBtn importance={importance} onClick={handleOpen} variant="outlined">
        <InfoOutlinedIcon sx={{ mr: 1 }} />
        {importance}
      </CustomBtn>
      <AnimatePresence mode="wait">
        {isOpen && (
          <Box
            key={isOpen}
            component={motion.section}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: [0.25, 0.62, 0.23, 0.98] }}
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              borderRadius: "20px",
              border: "2px solid #DDDCDF",
              p: 0.5,
              width: "330px",
              position: "absolute",
              right: "0",
              background: "white",
            }}
          >
            <ErrorOutlineIcon sx={{ mr: 1, color: "#DDDCDF" }} />
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem variant="middle" />}
              spacing={2}
            >
              <Typography
                onClick={() => handleClick("High")}
                variant="body2"
                color="primary"
                sx={{ cursor: "pointer" }}
              >
                High
              </Typography>
              <Typography
                onClick={() => handleClick("Medium")}
                variant="body2"
                sx={{ cursor: "pointer", color: "#FC9E5E" }}
              >
                Medium
              </Typography>
              <Typography
                onClick={() => handleClick("Low")}
                variant="body2"
                sx={{ cursor: "pointer", color: "#28B998" }}
              >
                Low
              </Typography>
              <Typography
                onClick={() => handleClick("Inactive")}
                variant="body2"
                sx={{ cursor: "pointer", color: "#9A9A9A" }}
              >
                Inactive
              </Typography>
            </Stack>
          </Box>
        )}
      </AnimatePresence>
    </Box>
  );
};

const switchColor = (importance) => {
  switch (importance) {
    case "High":
      return "#F05673";
    case "Low":
      return "#28B998";
    case "Medium":
      return "#FC9E5E";
    case "Inactive":
      return "#9A9A9A";
    default:
      return "#F05673";
  }
};

const CustomBtn = styled(Button)(({ importance }) => ({
  width: "120px",
  heigth: "40px",
  borderRadius: "30px",
  borderWidth: "2px",
  borderColor: "#DDDCDF",
  color: switchColor(importance),
  padding: "8px",
  "&:hover": {
    borderColor: switchColor(importance),
    borderWidth: "2px",
  },
}));

export default ImportanceBtn;
