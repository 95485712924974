import React from "react";
import Input from "../../components/Shared/Input";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import GradientButton from "../../components/Shared/GradientButton";
import { Box, Typography } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";

const ResetPwdStep = () => {
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, "le mot de passe doit faire entre 6 et 20 caractères")
        .max(20, "le mot de passe doit faire entre 6 et 20 caractères")
        .required("Le mot de passe est requis"),
      confirmPassword: Yup.string()
      .min(6, "le mot de passe doit faire entre 6 et 20 caractères")
      .max(20, "le mot de passe doit faire entre 6 et 20 caractères")
      .required("La confirmation du mot de passe est requise")
      .oneOf([Yup.ref("password"), null], "Les mots de passes de correspondent pas"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });
  return (
    <>
      <Typography variant="h4" align="center" sx={{ mb: 4 }}>
        réinitialisez votre mot de passe
      </Typography>{" "}
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}
      >
        <Input
          elevation={0}
          required
          type="password"
          error={Boolean(formik.touched.password && formik.errors.password)}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.password}
          helperText={formik.touched.password && formik.errors.password}
          id="password"
          name="password"
          placeholder="nouveau mot de passe"
          icon={LockOpenOutlinedIcon}
          sx={{ mb: 3 }}
        />
        <Input
          elevation={0}
          required
          type="password"
          error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.confirmPassword}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          id="confirmPassword"
          name="confirmPassword"
          placeholder="Re-taper le nouveau mot de passe"
          icon={LockOpenOutlinedIcon}
          sx={{ mb: 3 }}
        />
        <GradientButton sx={{ mt: 4 }} type="submit">
          Fait
        </GradientButton>
      </Box>
    </>
  );
};

export default ResetPwdStep;
