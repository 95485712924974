import { createContext } from "react";

export default createContext({
  expanded: false,
  setExpanded: () => {},
  ownerID: "1",
  setOwnerId: () => {},
  ownerStatus: "",
  setOwnerStatus: () => {},
});
