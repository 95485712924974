import React, { memo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Handle } from "react-flow-renderer";
import "./style.css";
import EditIcon from "@mui/icons-material/Edit";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import AddBtn from "./OptionsNode/AddBtn";

export default memo(({ data, isConnectable }) => {
  const [isHovered, setIsHovered] = useState(false);
  const toggleHover = () => {
    setIsHovered(!isHovered);
  };
  const calculSin = (angle) => {
    let width = parseInt(data.width.replace("px", ""));

    return (width / 2 + 15) * Math.sin(Math.PI / (360 / angle));
  };
  const calculCos = (angle) => {
    let width = parseInt(data.width.replace("px", ""));
    return (width / 2 + 15) * Math.cos(Math.PI / (360 / angle));
  };

  return (
    <Box
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#333",
        border: "none",
        width: data.width,
        height: data.height,
        borderRadius: "50%",
        background: data.bgColor,
        outline: isHovered ? "5px solid #F5F5F5" : "",
      }}
    >
      <Handle
        type="target"
        position="top"
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <PlayCircleOutlineIcon
        onClick={() => console.log("hhi")}
        sx={{
          display: isHovered ? "block" : "none",
          cursor: "pointer",
          width: "15px",
          height: "15px",
          position: "absolute",
          transform: `translate(${-calculCos(30) + "px"}, ${calculSin(30) + "px"}) `,
        }}
      />
      <EditIcon
        onClick={() => console.log("hhi")}
        sx={{
          display: isHovered ? "block" : "none",
          cursor: "pointer",
          width: "15px",
          height: "15px",
          position: "absolute",
          transform: `translate(${-calculCos(90) + "px"}, ${calculSin(90) + "px"}) `,
        }}
      />
      <AddBtn
        id={data.id}
        sx={{
          display: isHovered ? "block" : "none",
          cursor: "pointer",
          width: "15px",
          height: "15px",
          position: "absolute",
          transform: `translate(${-calculCos(150) + "px"}, ${calculSin(150) + "px"}) `,
        }}
      />
      <Typography variant="body1" textAlign="center">
        {data.label}
      </Typography>
      <Box component="ul" className="circle" sx={{ display: isHovered ? "block" : "none" }}>
        <Box component="li" className="li-btn li-btn1" />
        <Box component="li" className="li-btn li-btn2" />
        <Box component="li" className="li-btn li-btn3" />
      </Box>
      <Handle
        type="source"
        position="bottom"
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
    </Box>
  );
});
