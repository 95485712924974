import { Box, FormControlLabel, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomizedCheckbox from "../Shared/CustomizedCheckbox";

const CreateModules = ({ modules, checkedList, setCheckedList }) => {
  return (
    <Box sx={{ flex: 1 }}>
      <Typography variant="h5" color="primary" sx={{ mb: 7 }}>
        Nous vous conseillons d'ajouter ces modules
      </Typography>
      <Grid container spacing={5}>
        {modules.map((element, key) => (
          <CheckModule
            key={key}
            label={element}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
          />
        ))}
      </Grid>
    </Box>
  );
};

CreateModules.defaultProps = {
  modules: [],
};
export default CreateModules;

const CheckModule = ({ label, checkedList, setCheckedList }) => {
  const [checked, setChecked] = useState(true);
  const handleChange = (event) => {
    if (event.target.checked && !checkedList.includes(label)) {
      setCheckedList([...checkedList, label]);
    } else {
      setCheckedList(checkedList.filter((e) => e !== label));
    }
    setChecked(event.target.checked);
  };
  return (
    <Grid item md={6} xs={12}>
      <FormControlLabel
        label={<Typography variant="h5">{label}</Typography>}
        labelPlacement="start"
        control={<CustomizedCheckbox checked={checked} onChange={handleChange} />}
      />
    </Grid>
  );
};
