import React from "react";
import { Box, Typography } from "@mui/material";
import GradientButton from "../Shared/GradientButton";
import imgBot from "../../assets/images/bot.png";
const GrettingStep = ({ handleNext }) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
      }}
    >
      <img
        src={imgBot}
        alt="bot"
        style={{ marginRight: "10px", filter: "drop-shadow(0px 0px 2px rgba(0,0,0,0.2))" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "550" }}>
          Bonjour Samuel,
        </Typography>
        <Typography variant="h4" align="center" sx={{ fontWeight: "550", mt: 1 }}>
          Je suis Divi, votre consultant virtuel
        </Typography>
        <Typography variant="h5" align="center" sx={{ mt: 3 }}>
          Je suis disponible pour vous accompagner dans votre transformation digitale
        </Typography>
        <GradientButton
          onClick={handleNext}
          sx={{
            mt: 5,
          }}
          label="Commençons"
        >
          Commençons
        </GradientButton>
      </Box>
    </Box>
  );
};

export default GrettingStep;
