import React from "react";
import MapFlow from "./MapFlow";
import { ReactFlowProvider } from "react-flow-renderer";
import "./Style.css";
import Search from "./Search";
import Logo from "../../assets/images/Logo";
import { AppBar, Avatar, Box, Container, Toolbar, Typography } from "@mui/material";
import DotImg from "../../assets/images/DotImg";
import LineImg from "../../assets/images/LineImg";
import MapSelector from "./MapSelector";
import Menu from "./Menu";
const PrincipalMap = () => {
  return (
    <ReactFlowProvider>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          background: "white",
          zIndex: 1111,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              px: 0,
            }}
          >
            <Logo />
            <Box sx={{ display: { md: "block", xs: "none" } }}>
              <Search />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <DotImg />
              <MapSelector />
              <Menu />
            </Box>
            <Box sx={{ display: { md: "none", xs: "block" } }}>
              {/* <HamburgerMenu color="black" /> */}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <MapFlow />
    </ReactFlowProvider>
  );
};

export default PrincipalMap;
