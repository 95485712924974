import React from "react";
import { ButtonBase, styled, Typography } from "@mui/material";

const GradientButton = (props) => {
  const { children, ...rest } = props;
  return (
    <StyledBtn {...rest}>
      <Typography sx={{ fontWeight: "550" }}>{children}</Typography>
    </StyledBtn>
  );
};

const StyledBtn = styled(ButtonBase)({
  borderRadius: "30px",
  padding: "5px",
  minWidth: "100px",
  height: "50px",
  boxShadow: "2px 1000px 1px #fff inset",
  border: "solid 3px transparent",
  backgroundImage:
    " linear-gradient(90deg, rgba(20,16,45,1) 0%, rgba(208,13,79,0.6225840678068102) 100%, rgba(240,86,115,1) 100%), linear-gradient(90deg, rgba(20,16,45,1) 0%, rgba(208,13,79,0.6225840678068102) 100%, rgba(240,86,115,1) 100%)",
  backgroundOrigin: "border-box",
  backgroundClip: "content-box, border-box",
});
export default GradientButton;
