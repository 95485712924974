import {
  Avatar,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import React from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import NotListedLocationOutlinedIcon from "@mui/icons-material/NotListedLocationOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LogoutIcon from "@mui/icons-material/Logout";
const Menu = () => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <Avatar
        ref={anchorRef}
        onClick={handleToggle}
        sx={{ cursor: "pointer" }}
        alt="Remy Sharp"
        src="https://mui.com/static/images/avatar/1.jpg"
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
        modifiers={[
          {
            name: "flip",
            enabled: false,
          },
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "right top",
            }}
          >
            <Paper elevation={3} sx={{ mt: 2, p: 1, borderRadius: "12px" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <Stack>
                  <Profil />
                  <Divider sx={{ my: 0.5 }} />
                  <MenuList>
                    <MenuItem>
                      <ListItemIcon>
                        <LockOutlinedIcon fontSize="small" color="primary" />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          Modifier le mot de passe
                        </Typography>
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem>
                      <ListItemIcon>
                        <CalendarMonthOutlinedIcon fontSize="small" color="primary" />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          Calendrier
                        </Typography>
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem>
                      <ListItemIcon>
                        <NotListedLocationOutlinedIcon fontSize="small" color="primary" />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          Aide et FAQ
                        </Typography>
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem>
                      <ListItemIcon>
                        <SupportAgentIcon fontSize="small" color="primary" />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          Assistance
                        </Typography>
                      </ListItemIcon>
                    </MenuItem>
                    <Divider />
                    <MenuItem>
                      <ListItemIcon>
                        <LogoutIcon fontSize="small" color="primary" />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          Se déconnecter
                        </Typography>
                      </ListItemIcon>
                    </MenuItem>
                  </MenuList>
                </Stack>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const Profil = () => {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent={"space-between"}
      sx={{ height: 44 }}
      spacing={1}
    >
      <Avatar
        alt="Remy Sharp"
        sx={{ objectFit: "cover", height: "100%", mr: 1 }}
        src="https://mui.com/static/images/avatar/1.jpg"
      />
      <Stack alignItems="center">
        <Typography align="center" variant="body2">
          Samual Frederick
        </Typography>
        <Typography align="center" color="primary" variant="body2">
          Lacus Industries
        </Typography>
      </Stack>
      <IconButton size="small" color="primary">
        <EditOutlinedIcon fontSize="14px" />
      </IconButton>
    </Stack>
  );
};

export default Menu;
