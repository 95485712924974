import React from "react";
import * as Yup from "yup";
import Input from "../../components/Shared/Input";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Grid, Link } from "@mui/material";
import UnderlinedTypography from "../../components/Shared/UnderlinedTypography";
import GradientButton from "../../components/Shared/GradientButton";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import { useFormik } from "formik";
import CenterPaper from "../../components/Shared/CenterPaper";

const SignIn = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("L'e-mail n'est pas valide").required("L'e-mail est requis"),
      password: Yup.string()
        .min(6, "le mot de passe doit faire entre 6 et 20 caractères")
        .max(20, "le mot de passe doit faire entre 6 et 20 caractères")
        .required("Le mot de passe est requis"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const handleClick = () => {
    navigate("/map");
  };
  return (
    <CenterPaper elevation={4} sx={{ py: 2, px: 3 }}>
      <UnderlinedTypography variant="h4" align={"center"} sx={{ mb: 4 }}>
        Connexion
      </UnderlinedTypography>
      <form onSubmit={formik.handleSubmit}>
        <Input
          elevation={0}
          required
          type="email"
          error={Boolean(formik.touched.email && formik.errors.email)}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}
          helperText={formik.touched.email && formik.errors.email}
          id="email"
          name="email"
          placeholder="E-mail"
          icon={AlternateEmailOutlinedIcon}
          sx={{ mb: 3 }}
        />
        <Input
          elevation={0}
          required
          type="password"
          error={Boolean(formik.touched.password && formik.errors.password)}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.password}
          helperText={formik.touched.password && formik.errors.password}
          id="password"
          name="password"
          placeholder={"Mot de passe"}
          icon={LockOpenOutlinedIcon}
          sx={{ mb: 4 }}
        />
        <GradientButton
          onClick={handleClick}
          type="submit"
          sx={{ px: 2, mb: 4, display: "block", mx: "auto" }}
        >
          se connecter
        </GradientButton>
      </form>
      <Grid container>
        <Grid item xs>
          <Link color="primary" variant="body2" component={RouterLink} to="/forgotpassword">
            Mot de passe oublié?
          </Link>
        </Grid>
        <Grid item>
          <span style={{ fontSize: "14px" }}>Pas de compte?</span>{" "}
          <Box component={"br"} variant="br" sx={{ display: { md: "none", xs: "block" } }} />
          <Link color="primary" component={RouterLink} to="/" variant="body2">
            inscrivez-vous
          </Link>
        </Grid>
      </Grid>
    </CenterPaper>
  );
};

export default SignIn;
