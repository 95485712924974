import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import Logo from "../../assets/images/Logo";
import Menu from "./Menu";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        background: "rgba(255,255,255,0.8)",
        transition: ".2s",
        // backdropFilter: scrolled ? "blur(5px)" : "inherit",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 0,
          }}
        >
          <Logo style={{ cursor: "pointer" }} onClick={() => navigate("/")} />
          <Box >
            <Menu />
          </Box>
          {/* <Box sx={{ display: { md: "none", xs: "block" } }}>
            <HamburgerMenu color="black" />
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
