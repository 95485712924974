import React, { useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Box, Paper } from "@mui/material";
import { ContentPlaceholder } from "./ContentAccordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionContext from "../../context/accordionContext";
import nodeContext from "../../context/nodeContext";
import { deselectAll } from "../../utils/nodes";

const Accordion = () => {
  const { expanded: isOpen, setExpanded } = useContext(AccordionContext);
  const { setNodes } = useContext(nodeContext);
  const handleClose = () => {
    setExpanded(false);
    deselectAll(setNodes);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        elevation={0}
        component={motion.header}
        sx={{
          borderRadius: "unset",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          color: "white",
          cursor: "pointer",
          height: "40px",
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        initial={false}
        animate={{ backgroundColor: "#FFF" }}
        onClick={handleClose}
      >
        <ExpandMoreIcon
          sx={{
            display: isOpen ? "block" : "none",
          }}
        />
      </Paper>
      <AnimatePresence initial={false}>
        {isOpen && (
          <Box
            component={motion.section}
            sx={{ overflow: "hidden" }}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 1, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ContentPlaceholder />
          </Box>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default Accordion;
