import React, { useContext } from "react";
import { useState } from "react";
import {
  Avatar,
  Box,
  Container,
  LinearProgress,
  linearProgressClasses,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import GradientButton from "../Shared/GradientButton";
import accordionContext from "../../context/accordionContext";

const DetailsModule = ({ ModuleName,description, handleNext }) => {
  const [progress, setProgress] = useState(50);
  const { ownerStatus } = useContext(accordionContext);
  return (
    <Box
      sx={{
        height: "45vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-end",
        px: 3,
        pb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
        >
        { ModuleName && <Typography variant="h6" sx={{ position: "absolute", top: "20px" }}>
        {ModuleName}
          </Typography>}
          <Stack direction="row" spacing={20} sx={{ mb: 4 }}>
            <CardPercentage percentage={"20"} label="Maturité" />
            <CardPercentage percentage={"40"} label="Cible" />
          </Stack>
          <LinearProgressWithLabel value={progress} />
          {description && (
            <Typography variant="h6" sx={{ fontWeight: "450", mt: 3 }}>
              {description}
            </Typography>
          )}
        </Container>
        {ownerStatus !== "executed" && handleNext && (
          <GradientButton onClick={handleNext}>Lancer</GradientButton>
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          alt="Remy Sharp"
          sx={{ width: 24, height: 24, mr: 1 }}
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Typography variant="caption">Dernière exécution le 01/11/2022 à 10h30</Typography>
      </Box>
    </Box>
  );
};

const CardPercentage = ({ percentage, label, ...rest }) => {
  return (
    <Box {...rest}>
      <Typography variant="h1" sx={{ fontWeight: "400" }}>
        {percentage}%
      </Typography>
      <Typography align="center" variant="h4" color="primary">
        {label}
      </Typography>
    </Box>
  );
};

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box sx={{ width: "100%", height: "35px", position: "relative" }}>
        <Typography
          variant="body1"
          color="secondary"
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "50%",
            left: `${Math.round(props.value)}%`,
            transform: "translate(-50%,-50%)",
          }}
        >
          {`${Math.round(props.value)}%`}
          <ArrowDropDownIcon sx={{ mt: -1 }} />
        </Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  overflowY: "visible",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E9E9E9",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default DetailsModule;
