import React from "react";
import { Grid, Typography } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import GroupOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PublicIcon from "@mui/icons-material/Public";
import GradientButton from "../../components/Shared/GradientButton";
import Input from "../../components/Shared/Input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const Register = ({ isCompany }) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      companyName: "",
      lastName: "",
      firstName: "",
      email: "",
      phone: "",
      contry: "",
    },
    validationSchema: Yup.object({
      lastName: Yup.string()
        .max(20, "le nom ne doit pas dépasser 20 caractères")
        .required("Le nom est requis"),
      contry: Yup.string().required("pays est requis"),
      companyName: isCompany
        ? Yup.string().max(20, "le nom de sociéte ne doit pas dépasser 20 caractères")
        : Yup.string(),
        firstName: Yup.string()
        .max(20, '"le prénom ne doit pas dépasser 20 caractères"')
        .required("Le prénom est requis"),
      email: Yup.string()
        .email("L'e-mail n'est pas valide")
        .required("L’adresse e-mail est requise"),
      phone: Yup.string()
        .matches(phoneRegExp, "Le numéro de téléphone n'est pas valide")
        .required("le numéro de téléphone est requis"),
    }),
    onSubmit: (values) => {
      navigate("/signup");
    },
  });
  return (
    <form
      style={{ width: "100%" }}
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit(e);
      }}
    >
      <Typography variant="h6" sx={{ maxWidth: "180px", mb: 2 }}>
        Veuillez créer votre compte
      </Typography>
      <Grid container spacing={2}>
        {isCompany && (
          <Grid item xs={12}>
            <Input
              required
              error={Boolean(formik.touched.companyName && formik.errors.companyName)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.companyName}
              helperText={formik.touched.companyName && formik.errors.companyName}
              id="companyName"
              name="companyName"
              placeholder="Nom de l'entreprise"
              icon={GroupOutlinedIcon}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Input
            required
            error={Boolean(formik.touched.firstName && formik.errors.firstName)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.firstName}
            helperText={formik.touched.firstName && formik.errors.firstName}
            id="firstName"
            name="firstName"
            placeholder="Prénom"
            icon={PersonOutlineOutlinedIcon}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            required
            error={Boolean(formik.touched.lastName && formik.errors.lastName)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.lastName}
            helperText={formik.touched.lastName && formik.errors.lastName}
            id="lastName"
            name="lastName"
            placeholder="Nom"
            icon={PersonRoundedIcon}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required
            type="email"
            error={Boolean(formik.touched.email && formik.errors.email)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
            helperText={formik.touched.email && formik.errors.email}
            id="email"
            name="email"
            placeholder="E-mail"
            icon={AlternateEmailOutlinedIcon}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required
            error={Boolean(formik.touched.phone && formik.errors.phone)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.phone}
            helperText={formik.touched.phone && formik.errors.phone}
            id="phone"
            name="phone"
            placeholder="Numéro de téléphone"
            icon={LocalPhoneOutlinedIcon}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required
            error={Boolean(formik.touched.contry && formik.errors.contry)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.contry}
            helperText={formik.touched.contry && formik.errors.contry}
            id="contry"
            name="contry"
            placeholder="Pays"
            icon={PublicIcon}
          />
        </Grid>
      </Grid>
      <GradientButton type="submit" sx={{ display: "block", ml: "auto" }}>
        valider
      </GradientButton>
    </form>
  );
};

export default Register;
