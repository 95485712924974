import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Answer from "./Answer";
import ImportanceBtn from "./ImportanceBtn";
import { useState } from "react";
import { questions } from "../../mocks/questions";
import HeadQuestion from "./HeadQuestion";
import FooterQuestion from "./FooterQuestion";
import OverallScore from "../OverallScore.js/OverallScore";
import { useReactFlow } from "react-flow-renderer";
import accordionContext from "../../context/accordionContext";
import nodeContext from "../../context/nodeContext";
import { useChild } from "../../hooks/useChild";
import { useContext } from "react";
import {
  changeLabelById,
  changeStatusModule,
  deselectAll,
  transitionNode,
} from "../../utils/nodes";

const QuestionModule = ({ modules }) => {
  const [globalActual, setGlobalActual] = useState([]);
  const [globalTarget, setGlobalTarget] = useState([]);
  const [importance, setImportance] = useState("Low");
  const [activePage, setActivePage] = useState(0);
  const { fitView } = useReactFlow();
  const { setExpanded, ownerID, setOwnerStatus } = useContext(accordionContext);
  const { setNodes } = useContext(nodeContext);
  const [checkedList, setCheckedList] = useState(modules);
  const addChild = useChild();
  const handleValidate = () => {
    transitionNode(ownerID, setNodes);
    changeLabelById(ownerID, setNodes, "nom de société");
    checkedList.map((child, index) => addChild(ownerID, child, index, checkedList));
    setExpanded(false);
    setOwnerStatus("executed");
    changeStatusModule(ownerID, "executed", setNodes);
    setTimeout(() => fitView(), 100);
    deselectAll(setNodes);
  };
  return (
    <Container sx={{ height: "80vh", overflow: "auto" }} maxWidth="lg">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          pt: 0.5,
        }}
      >
        {activePage + 1 <= questions.length ? (
          <Box>
            <HeadQuestion     activePage={activePage}/>
            <Box
              sx={{
                mt: 2.5,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                mb: 5,
              }}
            >
              <Typography variant="h6" color="primary" sx={{ fontWeight: "400" }}>
                {questions[activePage].question}
              </Typography>
              <ImportanceBtn importance={importance} setImportance={setImportance} />
            </Box>
            {questions[activePage].response.map((q, key) => (
              <Answer
                key={key}
                activePage={activePage}
                text={q}
                globalActual={globalActual}
                globalTarget={globalTarget}
                setGlobalActual={setGlobalActual}
                setGlobalTarget={setGlobalTarget}
              />
            ))}
          </Box>
        ) : (
          <OverallScore
            modules={modules}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
          />
        )}
        <FooterQuestion
          activePage={activePage}
          setActivePage={setActivePage}
          questions={questions}
          handleValidate={handleValidate}
        />
      </Box>
    </Container>
  );
};

export default QuestionModule;
