import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import CreateModules from "../Shared/CreateModules";

const OverallScore = ({ modules, checkedList, setCheckedList }) => {
  return (
    <Box
      sx={{
        minHeight: "45vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pb: 2,
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: "550", mb: 7 }}>
        Résultat
      </Typography>
      <Stack
        direction="row"
        spacing={15}
        sx={{ mb: 5 }}
      >
        <CardPercentage percentage={"20"} label="Maturité" />
        <CardPercentage percentage={"40"} label="Cible" />
      </Stack>
      <CreateModules checkedList={checkedList} setCheckedList={setCheckedList} modules={modules} />
    </Box>
  );
};

const CardPercentage = ({ percentage, label, ...rest }) => {
  return (
    <Box {...rest}>
      <Typography variant="h1" sx={{ fontWeight: "400" }}>
        {percentage}%
      </Typography>
      <Typography align="center" variant="h6" color="primary">
        {label}
      </Typography>
    </Box>
  );
};
export default OverallScore;
