import { alpha, styled } from "@mui/material";

export const CodeInputField = styled("input")(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: alpha("#A6ADB5", 0.15),
  boxShadow: theme.shadows[1],
  borderRadius: "11px",
  fontFamily: "Poppins",
  fontSize: "36px",
  fontWeight: 600,
  textAlign: "center",
  width: "60px",
  height: "60px",
  border: "1px solid grey",
  "&:hover": {
    appearance: "none",
    outline: "none",
    border: `1px solid ${theme.palette.text.primary}`,
  },
  "&:focus": {
    appearance: "none",
    outline: "none",
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));
