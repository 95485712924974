import { Position } from "react-flow-renderer";

const deselectAll = (setNodes) => {
  setNodes((nds) =>
    nds.map((node) => {
      if (node.selected) {
        return {
          ...node,
          selected: false,
        };
      }
      return node;
    })
  );
};
const changeStatusModule = (id, newStatus, setNodes) => {
  setNodes((nds) =>
    nds.map((node) => {
      if (node.id === id) {
        node.data = {
          ...node.data,
          status: newStatus,
        };
      }
      return node;
    })
  );
};

const colors = ["#FFB680", "#D89292", "#EDC3C3", "#F0C453", "#3D5F6F"];
const changeLabelById = (id, setNodes, newLabel) => {
  const randomColor = Math.floor(Math.random() * colors.length);
  setNodes((nds) =>
    nds.map((node) => {
      if (node.id === id) {
        if (id === "1") {
          node.data = {
            ...node.data,
            icon: true,
            label: newLabel,
            bgColor: "#8851A0",
          };
        } else if (id === "1-1") {
          node.data = {
            ...node.data,
            bgColor: "#FDB783",
          };
        } else if (id === "1-1-1") {
          node.data = {
            ...node.data,
            bgColor: "#d89292",
          };
        } else if (id === "1-1-1-1") {
          node.data = {
            ...node.data,
            bgColor: "#3d5f6f",
          };
        } else
          node.data = {
            ...node.data,
            bgColor: colors[randomColor],
          };
      }
      return node;
    })
  );
};

const transitionNode = (id, setNodes) => {
  setNodes((nds) =>
    nds.map((node) => {
      if (node.id === id && id !== "1") {
        node.position = {
          x: node.position.x + 120,
          y: node.position.y + 150,
        };
      }
      return node;
    })
  );
};

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection(intersectionNode, targetNode) {
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const {
    width: intersectionNodeWidth,
    height: intersectionNodeHeight,
    positionAbsolute: intersectionNodePosition,
  } = intersectionNode;
  const targetPosition = targetNode.positionAbsolute;

  const w = intersectionNodeWidth / 2;
  const h = intersectionNodeHeight / 2;

  const x2 = intersectionNodePosition.x + w;
  const y2 = intersectionNodePosition.y + h;
  const x1 = targetPosition.x + w;
  const y1 = targetPosition.y + h;

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
  const xx3 = a * xx1;
  const yy3 = a * yy1;
  const x = w * (xx3 + yy3) + x2;
  const y = h * (-xx3 + yy3) + y2;

  return { x, y };
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
  const n = { ...node.positionAbsolute, ...node };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + n.width - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + n.height - 1) {
    return Position.Bottom;
  }

  return Position.Top;
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
  const sourceIntersectionPoint = getNodeIntersection(source, target);
  const targetIntersectionPoint = getNodeIntersection(target, source);

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
  };
}

export { transitionNode, changeStatusModule, deselectAll, changeLabelById };
